import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DynamicFormControlComponent,
  DynamicFormLayoutService,
  DynamicFormValidationService
} from "@ng-dynamic-forms/core";
import { DynamicTextWithAttachmentsModel } from "./dynamic-text-with-attachments.model";
import { TextWithAttachmentsComponent } from "../../components/text-with-attachments/text-with-attachments.component";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'dynamic-text-with-attachments',
  templateUrl: './dynamic-text-with-attachments.component.html',
  standalone: true,
  imports: [
    TextWithAttachmentsComponent,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTextWithAttachmentsComponent extends DynamicFormControlComponent {
  @Input() override group!: FormGroup;
  @Input() override model!: DynamicTextWithAttachmentsModel;

  @Output() override blur: EventEmitter<any> = new EventEmitter();
  @Output() override change: EventEmitter<any> = new EventEmitter();
  @Output() override focus: EventEmitter<any> = new EventEmitter();

  constructor(layoutService: DynamicFormLayoutService, validationService: DynamicFormValidationService) {
    super(layoutService, validationService);
  }
}
