<ngx-file-drop (onFileDrop)="onFilesDropped($event)" dropZoneClassName="drop-zone" contentClassName="drop-content">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <ckeditor [editor]="CKEditor" [config]="{toolbar: []}" [(ngModel)]="value.text" (change)="onChange(value)"
              (focus)="onTouched()" [disabled]="disabled"/>
    <mat-chip-listbox aria-label="Anhänge" class="mt-4" *ngIf="value.attachments.length">
      <mat-chip *ngFor="let item of value.attachments;" (removed)="deleteAttachment(item)">
        {{ item.name }}
        <button matChipRemove aria-label="Anhang entfernen">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-listbox>

    <button type="button" mat-raised-button color="primary" (click)="openFileSelector()" class="mt-4">
      <mat-icon>attachment</mat-icon>
      Anhang
    </button>
  </ng-template>
</ngx-file-drop>
