import { inject, Injectable } from '@angular/core';
import * as jose from 'jose';
import { Session } from "./Session";
import { AuthenticateGQL } from "../../graphql/generated";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private authenticateGQL = inject(AuthenticateGQL);

  login(username: string, password: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.authenticateGQL.mutate({
        data: {
          username,
          password
        }
      }).subscribe(response => {
        if (response.data?.authenticate.success) {
          localStorage.setItem('jwt', response.data.authenticate.jwt);
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }

  logout(): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.clear();
      resolve();
    });
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        let token: string = localStorage.getItem('jwt') || '';
        let expireTime = jose.decodeJwt(token).exp;

        if(expireTime == undefined)
          expireTime = 0;

        // @ts-ignore
        if(expireTime == 0 || jose.decodeJwt(token).exp < Math.floor(Date.now() / 1000)) throw 'Token Expired!';

        resolve(true);
      } catch(e) {
        resolve(false);
      }
    });
  }

  getCurrentSession(): Session | undefined {
    try {
      let token: string = localStorage.getItem('jwt') || '';
      let decodedJWT: any = jose.decodeJwt(token);

      return {
        employee: decodedJWT.employee
      };
    } catch(e) {
      return undefined;
    }
  }
}
