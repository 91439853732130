import {
  DynamicFormControlLayout,
  DynamicFormValueControlModel,
  DynamicFormValueControlModelConfig,
  serializable
} from "@ng-dynamic-forms/core";
import { TextWithAttachments } from "../../components/text-with-attachments/text-with-attachments.component";

export const DYNAMIC_FORM_CONTROL_TYPE_TEXT_WITH_ATTACHMENTS = "TEXT-WITH-ATTACHMENTS";

export class DynamicTextWithAttachmentsModel extends DynamicFormValueControlModel<unknown> {

  @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_TEXT_WITH_ATTACHMENTS;

  constructor(public config: DynamicFormValueControlModelConfig<TextWithAttachments>, layout?: DynamicFormControlLayout) {
    super(config, layout);
  }
}
