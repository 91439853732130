import { booleanAttribute, Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { NgxFileDropEntry, NgxFileDropModule } from "ngx-file-drop";
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator, Validators
} from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { File as ExistingFile } from "../../graphql/generated";

type Attachment = File | Pick<ExistingFile, 'id' | 'name'>;

export type TextWithAttachments = {
  text: string;
  attachments: Attachment[]
}

@Component({
  selector: 'text-with-attachments',
  standalone: true,
  imports: [
    CommonModule,
    CKEditorModule,
    MatButtonModule,
    MatIconModule,
    NgxFileDropModule,
    FormsModule,
    MatChipsModule
  ],
  templateUrl: './text-with-attachments.component.html',
  styleUrls: ['./text-with-attachments.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextWithAttachmentsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextWithAttachmentsComponent),
      multi: true
    }
  ]
})
export class TextWithAttachmentsComponent implements ControlValueAccessor, Validator {
  @Input({transform: booleanAttribute}) disabled: boolean = false;
  protected CKEditor = ClassicEditor;

  protected onChange: any;
  protected onTouched: any;

  value: TextWithAttachments = {
    text: '',
    attachments: []
  };

  writeValue(obj: TextWithAttachments): void {
    if (!obj) {
      return;
    }
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (!control.hasValidator(Validators.required)) {
      return null;
    }
    return this.value.text ? null : {required: true};
  }

  onFilesDropped(items: NgxFileDropEntry[]) {
    items.forEach(f => {
      const fileEntry = f.fileEntry as FileSystemFileEntry;
      fileEntry.file(file => {
        this.value.attachments.push(file);
      });
    });
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  deleteAttachment(attachment: Attachment) {
    this.value.attachments = this.value.attachments.filter(item => item !== attachment);
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
}
