import { NgModule, Type } from '@angular/core';
import {
  DYNAMIC_FORM_CONTROL_MAP_FN,
  DYNAMIC_MATCHER_PROVIDERS,
  DynamicFormControl,
  DynamicFormControlModel,
} from "@ng-dynamic-forms/core";
import { DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE } from "@pd/ngx-components";
import { DynamicEntityAutocompleteComponent } from "@pd/ngx-components";
import { DYNAMIC_FORM_CONTROL_TYPE_ENTITY_SELECT } from "@pd/ngx-components";
import { DynamicEntitySelectComponent } from "@pd/ngx-components";
import {
  DynamicTextWithAttachmentsComponent
} from "./dynamic-text-with-attachments/dynamic-text-with-attachments.component";
import {
  DYNAMIC_FORM_CONTROL_TYPE_TEXT_WITH_ATTACHMENTS
} from "./dynamic-text-with-attachments/dynamic-text-with-attachments.model";


@NgModule({
  providers: [
    {
      provide: DYNAMIC_FORM_CONTROL_MAP_FN,
      useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null => {
        switch (model.type) {
          case DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE:
            return DynamicEntityAutocompleteComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_ENTITY_SELECT:
            return DynamicEntitySelectComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_TEXT_WITH_ATTACHMENTS:
            return DynamicTextWithAttachmentsComponent;

          default:
            return null;

        }
      }
    },
    DYNAMIC_MATCHER_PROVIDERS,
  ]
})
export class DynamicFormsModule {
}
